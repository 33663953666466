import http from '@/utils/http'

export function login(data) {
    return http.post('/sessions', data)
}

export function logout() {
    return http.delete('/sessions')
}

export function verifyRRDToken() {
    return http.get('/verify_rrd_token')
}

export function forgetPassword(data) {
    return http.post('/reset_password', data)
}

export function updatePassword(params, data) {
    return http({method:'put', url: `/update_password`, params: params, data: data })
}
// http://kjtd-dev.liangzhicn.com/api/v1/update_password?email=admin%40gmail.com&expires=1626863426&signature=6e5c7f8f909f616985ce730b1d9c4ea6b971494d2cbac7da4172a750b9f94060

// https://kjtd-dev.liangzhicn.com/#/update_password?email=admin%40gmail.com&expires=1626863426&signature=6e5c7f8f909f616985ce730b1d9c4ea6b971494d2cbac7da4172a750b9f94060