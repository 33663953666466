<template>
  <div :style="`width:${width}; margin: 0 auto;`">
    <el-form :rules="rules" :model="form" ref="form">
      <div class="loginForm-container">
        <p>电子邮件地址：</p>
        <el-form-item prop="email">
          <el-input v-model="form.email" type="email">
            <i
              slot="prepend"
              class="el-icon-user"
              style="font-size: 20px; color: black"
            />
          </el-input>
          <span>密码重置的指示将发送至您登记的电子邮件地址</span>
        </el-form-item>
      </div>
      <div class="submit-container">
        <el-button
          @click="submitForm('form')"
          type="primary"
          style="width: 100%; font-size: 20px"
        >
          <span style="margin-left: 20px"> 提 交 </span>
        </el-button>
      </div>
      <div class="submit-container">
        <router-link :to="{ path: '/login' }" tag="div">
          <el-button style="width: 100%; font-size: 20px">
            <span style="margin-left: 20px"> 返 回 </span>
          </el-button>
        </router-link>
      </div>
    </el-form>
  </div>
</template>

<script>
import { forgetPassword } from "@/api/security";
const checkEmail = (rule, value, callback) => {
  const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
  if (!value) {
    return callback(new Error("email不能為空"));
  }
  setTimeout(() => {
    if (mailReg.test(value)) {
      callback();
    } else {
      callback(new Error("email格式不正確"));
    }
  }, 100);
};
export default {
  props: {
    width: String,
  },
  created() {
    this.changeVerifyCode();
  },
  data() {
    return {
      form: {
        email: "",
        verifyCode: "",
        codeKey: "",
      },
      img: "",
      rules: {
        email: [{ validator: checkEmail, trigger: "blur" }],
        verifyCode: [
          { required: true, message: "验证码不能為空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    changeVerifyCode() {
      // getVerifyCode().then(_ => {
      //     this.form.codeKey = _.data.codeKey
      //     this.img = _.data.verifyCode
      // })
    },
    submitForm(formName) {
      this.$refs[formName].validate(() => {
        //   if (valid) {
        forgetPassword(this.form)
          .then((_) => {
            this.$message.success("success！");
              let url = _.data.split("/").slice(3).join("/").split('#/')[1];
              // window.location.href = window.location.origin + "/" + url;
              this.$router.push(url);
          })
          .catch((error) => {
            if (error.response) {
              this.$message.error(error.response.data.error_message);
            }
          });
        //   } else {
        //     return false;
        //   }
      });
    },
  },
};
</script>

<style lang="scss">
.loginForm-container {
  p {
    color: gray;
  }
  .el-input-group__prepend {
    background-color: #fff;
  }
}
.changeLink {
  color: blue;
}
.changeLink:hover {
  color: purple;
  cursor: pointer;
}
.submit-container {
  margin-top: 30px;
  p {
    color: #36aa1d;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
}
.err {
  color: red;
  padding-top: 30px;
  text-align: center;
}
</style>