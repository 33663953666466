<template>
  <div :style="`width:${width}; margin: 0 auto;`">
    <el-form :rules="rules" :model="form" ref="form">
      <div class="loginForm-container">
        <p>账号：</p>
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="请输入账号">
            <i
              slot="prepend"
              class="el-icon-user"
              style="font-size: 20px; color: black"
            />
          </el-input>
        </el-form-item>
      </div>
      <div class="loginForm-container">
        <p>密码：</p>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            type="password"
            show-password
            placeholder="请输入密码"
          >
            <i
              slot="prepend"
              class="el-icon-lock"
              style="font-size: 20px; color: black"
            />
          </el-input>
        </el-form-item>
      </div>
      <div class="submit-container">
        <el-button
          @click="submitForm('form')"
          type="primary"
          style="width: 100%; font-size: 20px"
        >
          登入
        </el-button>
        <p>
          <router-link :to="{ path: '/forget_password' }" tag="a">
            Forgot Password 忘记密码
          </router-link>
        </p>
      </div>
    </el-form>
  </div>
</template>

<script>
import { login } from "@/api/security";
import { setToken, setUserInfo, setRefreshToken } from "@/utils/auth";
export default {
  props: {
    width: String,
  },
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
      rules: {
        name: [{ required: true, message: "用户名不能为空", trigger: "blur" },],
        password: [
          { required: true, message: "密碼不能為空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login(this.form)
            .then((v) => {
              if (v.data.accessToken) {
                setToken(v.data.accessToken);
                setRefreshToken(v.data.refreshToken);
                setUserInfo(v.data);
                this.$router.push("/");
              } else {
                this.$message.error(v.data["errorMsg"]);
              }
            })
            .catch((error) => {
              if (error.response) {
                this.$message.error(error.response.data.error_msg);
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.loginForm-container {
  p {
    color: gray;
  }
  .el-input-group__prepend {
    background-color: #fff;
  }
}
.submit-container {
  margin-top: 30px;
  p {
    color: #36aa1d;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
}
.err {
  color: red;
  padding-top: 30px;
  text-align: center;
}
</style>