<template>
  <div :style="`width:${width}; margin: 0 auto;`">
    <el-form :rules="rules" :model="form" ref="form">
      <div class="loginForm-container">
        <p>密码：</p>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            type="password"
            show-password
            placeholder="请输入密码"
          >
            <i
              slot="prepend"
              class="el-icon-lock"
              style="font-size: 20px; color: black"
            />
          </el-input>
        </el-form-item>
      </div>
      <div class="loginForm-container">
        <p>确认密码：</p>
        <el-form-item prop="repeatPassword">
          <el-input
            v-model="form.repeatPassword"
            type="password"
            show-password
            placeholder="请输入确认密码"
          >
            <i
              slot="prepend"
              class="el-icon-lock"
              style="font-size: 20px; color: black"
            />
          </el-input>
        </el-form-item>
      </div>
      <div class="submit-container">
        <el-button
          @click="submitForm('form')"
          type="primary"
          style="width: 100%; font-size: 20px"
        >
          下一步
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { updatePassword } from "@/api/security";
export default {
  props: {
    width: String,
  },
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
      params: "",
      rules: {
        password: [
          { required: true, message: "密碼不能為空", trigger: "blur" },
        ],
        repeatPassword: [{ validator: this.validatePass2, trigger: "blur" }],
      },
    };
  },
  created() {
    let { url, expires, signature } = this.$route.query;
    this.params = {
      email: url.split("=")[1],
      expires: expires,
      signature: signature,
    };
    console.log(this.params);
  },
  methods: {
    validatePass2(rule, value, callback) {
      if (value === "" || value === undefined) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePassword(this.params, { password: this.form.password })
            .then(() => {
              this.$message.success("success！");
                this.$router.push("/");
            })
            .catch((error) => {
              if (error.response) {
                this.$message.error(error.response.data.error_msg);
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.loginForm-container {
  p {
    color: gray;
  }
  .el-input-group__prepend {
    background-color: #fff;
  }
}
.submit-container {
  margin-top: 30px;
  p {
    color: #36aa1d;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
}
.err {
  color: red;
  padding-top: 30px;
  text-align: center;
}
</style>