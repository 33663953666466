<template>
  <div class="login-container">
    <div class="logo-container">
      <!-- <img src="@/assets/images/CCF_logo.png"> -->
    </div>
    <div
      style="
        background-color: #fefefe;
        padding: 20px 0 30px 0;
        border-radius: 10px;
        border: 1px solid gray;
      "
    >
      <h1 class="logoin-title">跨境推单前置机</h1>
      <login-form v-if="id === 0" :width="`65%`" />
      <forget v-else-if="id === 1" :width="`65%`" />
      <update-password v-else-if="id === 2" :width="`65%`" />
    </div>

    <!-- <forget v-else-if="id===1" :width='`65%`'/> -->
    <!-- <div class="contact">
      Contact Us 聯絡我們
    </div>
    <div class="foot"> 
      ©️ 2020 Children Chiropractic Foundation
    </div> -->
  </div>
</template>

<script>
import loginForm from "./loginForm";
import forget from "./forgetPassword";
import UpdatePassword from "./updatePassword.vue";
export default {
  props: {
    id: Number,
  },
  components: { loginForm, forget, UpdatePassword },
};
</script>

<style lang="scss" scoped>
.login-container {
  width: 35%;
  height: 100vh;
  margin: 0 auto;
  .logo-container {
    padding-top: 130px;
  }
  .logoin-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 50px;
    text-align: center;
  }
  .contact {
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    text-align: center;
  }
  .foot {
    margin-top: 10px;
    font-size: 18px;
    color: #868686;
    text-align: center;
  }
}
</style>